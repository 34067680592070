export const debugGtm = (debugMsg: string, data: any): void => {
  const isProd: boolean = process.env.NODE_ENV === 'production';

  // eslint-disable-next-line no-console
  if (!isProd) console.log(`${debugMsg}: `, data);
};

export const gtmPush = (data: any): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer?.push(data);

  debugGtm('GTM PUSH', data);
};

export async function sha256(message: string) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string

  return hashHex;
}
