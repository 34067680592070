import { Combobox } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeSearchBoxLocationsProps } from './types';

/**
 * SearchBoxLocations
 */
export const SearchBoxLocations: FunctionComponent<
  TypeSearchBoxLocationsProps
> = ({
  provinces,
  cities,
  onCitiesChange,
  onProvincesChange,
}: TypeSearchBoxLocationsProps) => (
  <>
    <Combobox
      values={provinces.values}
      onChange={(value) =>
        onProvincesChange(
          provinces.values.find((province) => province.id === value.id)
        )
      }
      {...(provinces.placeholder
        ? {
            placeholder: provinces.placeholder,
          }
        : {
            defaultValue: provinces.defaultValue,
          })}
    />

    <Combobox
      //eslint-disable-next-line
      //@ts-ignore
      values={cities?.cities?.[1]?.cities ?? []}
      onChange={(value) => onCitiesChange(value)}
      disabled={
        cities?.cities?.[1]?.cities
          ? cities?.cities[1]?.cities.length === 0
          : true
      }
      {...(cities.defaultValue
        ? {
            defaultValue: cities.defaultValue,
          }
        : {
            placeholder: cities.placeholder,
          })}
    />
  </>
);

SearchBoxLocations.displayName = 'SearchBoxLocations';
