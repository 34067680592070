import { useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { oneLine } from 'common-tags';

import ArrowDown from '@/assets/icons/ArrowDownPri.svg';
import Check from '@/assets/icons/CheckmarkCircle.svg';

import type { ArrayElement } from '@/types';
import type { FunctionComponent } from 'react';
import type { TypeComboboxProps } from './types';

/**
 * Combobox
 */
export const Combobox: FunctionComponent<TypeComboboxProps> = ({
  values,
  placeholder,
  onChange,
  className = '',
  defaultValue,

  disabled = false,
}: TypeComboboxProps) => {
  const [selected, setSelected] = useState<ArrayElement<typeof values>>(
    placeholder && !defaultValue
      ? { id: 'placeholder', label: placeholder }
      : defaultValue ?? values[0]
  );
  const onChangeHandler = (value: ArrayElement<typeof values>) => {
    setSelected(value);
    onChange && onChange(value);
  };

  //Este codigo esta para cuando buscamos una localizacion desde el hero,
  //pero luego desde el donde estamos solo cambios de provincia,
  //da un error porque como se ha eligido anteriormente una poblacion
  //deja la locazacion anterior como por defecto, entonces
  //compruebo si en la provincia exite, si es asi funciona normal,
  //sino dejo el valor por defecto del placeholder.
  // TODO: Revisar como se crea el default value con rodri.
  useEffect(() => {
    setSelected(
      placeholder && !defaultValue
        ? { id: 'placeholder', label: placeholder }
        : defaultValue ?? values[0]
    );
  }, [values, placeholder, defaultValue]);

  useEffect(() => {
    setSelected(
      placeholder && !defaultValue
        ? { id: 'placeholder', label: placeholder }
        : defaultValue ?? values[0]
    );
  }, []);
  return (
    <Listbox
      value={selected}
      onChange={onChangeHandler}
      by="id"
      disabled={disabled}
    >
      {({ open, value }) => (
        <>
          <div className={`relative mt-2 ${className}`}>
            <Listbox.Button
              className={({ disabled }) => oneLine`
                text-body--primary relative flex w-full items-center justify-between gap-2 rounded-full bg-white px-8 py-3 text-left text-text-primary
                ${disabled ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'}
              `}
            >
              <>
                <span className="block truncate">
                  {value?.label ?? placeholder}
                </span>
                <span className="pointer-events-none flex items-center">
                  <ArrowDown
                    className={oneLine`
                      h-4 w-4 transition-transform
                      ${open ? 'rotate-180' : ''}
                    `}
                    aria-hidden="true"
                  />
                </span>
              </>
            </Listbox.Button>

            <Transition
              show={open}
              as="div"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              unmount={false}
            >
              <Listbox.Options className="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-md bg-white py-1">
                {values.map((value) => (
                  <Listbox.Option
                    key={value.id}
                    value={value}
                    className={({ active, selected }) =>
                      oneLine`
                        relative flex cursor-default select-none items-center justify-between gap-6 truncate py-2 pl-3 pr-9
                        ${
                          active
                            ? 'bg-surface-10 text-white'
                            : 'text-text-primary'
                        }
                        ${selected ? 'font-bold' : 'cursor-pointer font-normal'}
                      `
                    }
                  >
                    {({ selected }) => (
                      <>
                        {value.label}
                        {selected && (
                          <Check className="h-4 w-4" aria-hidden="true" />
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

Combobox.displayName = 'Combobox';
